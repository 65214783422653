<template>

    <span>
      <v-layout mb-4>
        <v-flex xs10 ml-3>
          <h2 class="lime--text lighten-1 pt-3">Gerenciar Cupons - Inclusão</h2>
        </v-flex>
        <v-flex xs2 mr-3 text-right>
          <v-btn
            color="blue-grey"
            class="black--text"
            :to="{name: 'store-coupons'}"
          >
            <v-icon dark>keyboard_arrow_left</v-icon>
            Voltar
          </v-btn>
        </v-flex>
      </v-layout>

      <form-Store-coupon :btnLabel="btnLabel"></form-store-coupon>
    </span>
        
</template>

<script>
import FormStoreCouponComponent from "./partial/FormStoreCouponComponent";

export default {
  name: "AddStoreCouponComponent",
  data () {
    return {
        btnLabel: 'Incluir'
    }
  },
  methods: {

  },
  components: {
    formStoreCoupon: FormStoreCouponComponent,
  }
};
</script>

<style scoped>

</style>
